import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { TeamGroup } from "@store/team/team-types";
import { AddTeamProps } from "@store/team/team-slice-helper";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";

const mockedTeamGroups: TeamGroup[] = Array.from({ length: 70 }, (_, index) => {
  const showDescription = 10;

  const description =
    (index + 1) % showDescription === 0
      ? `Description for Group ${index + 1}`
      : undefined;

  let numMembers = 3;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (index % 3 === 0) {
    numMembers = 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (index % 5 === 0) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    numMembers = 5;
  }

  return {
    id: (index + 1).toString(),
    name: `Group ${index + 1}`,
    description,
    createdAt: "2023-09-17T12:00:00Z",
    sampleMembers: Array.from({ length: numMembers }, (_, memberIndex) => ({
      userResponse: {
        id: `user${index + 1}-${memberIndex + 1}`,
        identity: (index + 1).toString(),
        lastName: `LastName${index + 1}-${memberIndex + 1}`,
        firstName: `FirstName${index + 1}-${memberIndex + 1}`,
        email: `user${index + 1}-${memberIndex + 1}@example.com`,
      },
      createdAt: "2023-09-17T12:00:00Z",
    })),
  };
});

export const fetchTeam = createAsyncThunk<
  TeamGroup[],
  void,
  {
    state: RootState;
  }
>(
  "teams/fetchTeam",
  async () => {
    return new Promise<TeamGroup[]>((resolve) =>
      setTimeout(() => resolve(mockedTeamGroups), 0)
    );
  },
  {
    condition: (_, api) => {
      // Only fetch if the isAlphaTestingEnabled is true
      return api.getState().ui.isAlphaTestingEnabled;
    },
  }
);

/** Creates a team in the backend and adds it to the store */
export const createTeamGroup = createAsyncThunk<TeamGroup, AddTeamProps>(
  "teams/createTeamGroup",
  async ({
    coreApiClient,
    companyId,
    teamName,
    description,
    selectedMemberIds,
  }) => {
    try {
      // TODO: Add backend request to the backend
      // const team = await coreApiClient.V3.SDB.createTeam(companyId, {
      //   name: teamName,
      //   description,
      //   members: [
      //     {
      //       identities: selectedMemberIds,
      //     },
      //   ],
      // });

      const oneSecondInMilliseconds = 1000;
      const team = new Promise<TeamGroup>((resolve) =>
        setTimeout(
          () =>
            resolve({
              id: `${Date.now().toString()}`,
              name: teamName,
              description: description,
              createdAt: "2023-09-17T12:00:00Z",
              sampleMembers: [
                {
                  userResponse: {
                    id: `${Date.now().toString()}`,
                    identity: `${Date.now().toString()}`,
                    lastName: `LastName${Date.now().toString()}`,
                    firstName: `FirstName${Date.now().toString()}`,
                    email: `user${Date.now().toString()}@example.com`,
                  },
                  createdAt: "2023-09-17T12:00:00Z",
                },
              ],
            }),
          oneSecondInMilliseconds
        )
      );

      return team;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);
