import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchingStatus } from "@store/store-types";
import { createTeamGroup, fetchTeam } from "@store/team/team-thunk";
import { TeamGroup, TeamState } from "@store/team//team-types";

export const teamGroupAdapter = createEntityAdapter<TeamGroup>({
  selectId: (teamGroup) => teamGroup.id,
});

export const initialState: TeamState = {
  ...teamGroupAdapter.getInitialState(),
  status: FetchingStatus.uninitialized,
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    resetTeamState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeam.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(
        fetchTeam.fulfilled,
        (state, action: PayloadAction<TeamGroup[]>) => {
          state.status = FetchingStatus.succeeded;
          teamGroupAdapter.upsertMany(state, action.payload);
        }
      )
      .addCase(fetchTeam.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(createTeamGroup.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(createTeamGroup.fulfilled, (state, action) => {
        state.status = FetchingStatus.succeeded;
        teamGroupAdapter.setOne(state, action.payload);
      })
      .addCase(createTeamGroup.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      });
  },
});

export const { resetTeamState } = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
