import {
  Divider,
  PopperProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { sphereColors } from "@styles/common-colors";
import { SphereAvatar } from "@components/header/sphere-avatar";
import ContainsDescriptionSVG from "@assets/icons/new/contains-description.svg?react";
import { TeamGroup } from "@store/team/team-types";

/** Time to wait between hover and open the dialog */
const enterDelay = 200;

interface Props {
  /** The team group data to be displayed */
  groupName: TeamGroup;
}

/** Popper props to be used in the tooltip */
const tooltipPopperProps: Partial<PopperProps> = {
  sx: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px 0px rgba(133, 146, 173, 0.20)",
    },
  },
};

/**
 * The TeamGroupName component displays a team's group name and, if available, a description of the group.
 */
export function TeamGroupName({ groupName }: Props): JSX.Element {
  const styles = useMemo(
    () => ({
      stack: {
        gap: "8px",
        flexDirection: "row",
        alignItems: "center",
        cursor: groupName.description ? "pointer" : "default",
        "&:hover": {
          color: groupName.description ? sphereColors.blue500 : "",
        },
      },
    }),
    [groupName.description]
  );

  return (
    <Tooltip
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      PopperProps={tooltipPopperProps}
      placement="right"
      title={
        groupName.description && (
          <Stack component="div" padding={"8px"}>
            <Typography
              color={sphereColors.gray800}
              fontWeight={700}
              fontSize={"12px"}
            >
              Description
            </Typography>
            <Divider />

            <Typography
              component={"var"}
              color={sphereColors.gray800}
              fontSize={"12px"}
              paddingTop={"8px"}
            >
              {groupName.description}
            </Typography>
          </Stack>
        )
      }
    >
      <Stack component="div" sx={styles.stack}>
        <SphereAvatar
          initials={groupName.name
            .split(" ")
            .map((word) => word.charAt(0))
            .join("")}
          size="medium"
        />
        <Typography component={"var"} fontWeight={"bold"} fontSize={"12px"}>
          {groupName.name}
        </Typography>

        {groupName.description && (
          <ContainsDescriptionSVG data-testid="description-svg" />
        )}
      </Stack>
    </Tooltip>
  );
}
